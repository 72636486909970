/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Routes } from '../app/Routes'
import { I18nProvider } from '../_metronic/i18n'
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout'
import AuthInit from './modules/Auth/_redux/authInit'

export default function App({ persistor, basename }) {
	return (
		<PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
			{/* Add high level `Suspense` in case if was not handled inside the React tree. */}
			<React.Suspense fallback={<LayoutSplashScreen />}>
				{/* Override `basename` (e.g: `homepage` in `package.json`) */}
				<BrowserRouter basename={basename}>
					{/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
					<MaterialThemeProvider>
						{/* Provide `react-intl` context synchronized with Redux state.  */}
						<I18nProvider>
							<AuthInit>
								{/* Render routes with provided `Layout`. */}
								<Routes />
							</AuthInit>
						</I18nProvider>
					</MaterialThemeProvider>
				</BrowserRouter>
			</React.Suspense>
		</PersistGate>
	)
}
