import { createSlice } from '@reduxjs/toolkit'

const initialBookingsState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	staffs: [],
	bookingForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const bookingsSlice = createSlice({
	name: 'bookings',
	initialState: initialBookingsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getBookingById
		bookingFetched: (state, action) => {
			state.actionsLoading = false
			state.bookingForEdit = action.payload.bookingForEdit
			state.error = null
		},
		// findBookings
		bookingsFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// findStaffs
		staffsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.staffs = action.payload
		},
		// createBooking
		bookingCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.unshift(action.payload.data)
		},
		// updateBooking
		bookingUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.booking.id) {
					return action.payload.booking
				}
				return entity
			})
		},
		// reschedule
		reschedule: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { timesheet } = action.payload
			state.entities = state.entities.map((entity) => {
				if (timesheet.id === entity.timesheet.id) {
					entity.timesheet = {
						...entity.timesheet,
						date: timesheet.date,
						start_time: new Date(
							`${timesheet.date} ${timesheet.start_time}+0000`
						),
						end_time: new Date(`${timesheet.date} ${timesheet.end_time}+0000`),
					}
				}
				return entity
			})
		},
		// reschedule
		transfer: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { timesheet, data } = action.payload
			state.entities = state.entities.map((entity) => {
				if (Number(timesheet.booking_id) === entity.id) {
					entity = {
						...entity,
						staff: { ...data.staff },
						timesheet: {
							...entity.timesheet,
							date: data.date,
							start_time: new Date(`${data.date} ${data.start_time}+0000`),
							end_time: new Date(`${data.date} ${data.end_time}+0000`),
						},
					}
				}
				return entity
			})
		},
		// deleteBooking
		bookingDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id
			)
		},
		// deleteBookings
		bookingsDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id)
			)
		},
		// bookingsUpdateState
		bookingsStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
		// bookingsUpdateState
		bookingStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { id, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (id === entity.id) {
					entity.status = status
				}
				return entity
			})
		},
		// bookingsUpdateReview
		bookingReviewCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const review = action.payload.data
			state.entities = state.entities.map((entity) => {
				if (review.booking_id === entity.id) {
					entity.reviews.push(review)
				}
				return entity
			})
		},
	},
})
