import {createSlice} from "@reduxjs/toolkit";

const initialDashboardState = {
  listLoading: false,
  actionsLoading: false,
  filters: {},
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const generalSlice = createSlice({
  name: "general",
  initialState: initialDashboardState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getFilters
    filtersFetched: (state, action) => {
      state.actionsLoading = false;
      const object = action.payload.filters
      for (const property in object) {
        state.filters[property] = object[property]
      }
      state.error = null;
    },
  }
});
