import { createSlice } from '@reduxjs/toolkit'

const initialAvailabilitiesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	availabilityForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const availabilitiesSlice = createSlice({
	name: 'availabilities',
	initialState: initialAvailabilitiesState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getAvailabilityById
		availabilityFetched: (state, action) => {
			state.actionsLoading = false
			state.availabilityForEdit = action.payload.availabilityForEdit
			state.error = null
		},
		// findAvailabilities
		availabilitiesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// createAvailability
		availabilityCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(...action.payload.data)
		},
		// updateAvailability
		availabilityUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.availability.id) {
					return action.payload.availability
				}
				return entity
			})
		},
		// deleteAvailability
		availabilityDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deleteAvailabilities
		availabilitiesDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// availabilitiesUpdateState
		availabilitiesStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
