import * as requestFromServer from './settingsCrud'
import { settingsSlice, callTypes } from './settingsSlice'

const { actions } = settingsSlice

export const fetchSettings = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.findSettings()
		if (response.status !== 200) throw response
		const entities = response.data.data
		dispatch(actions.settingsFetched({ entities }))
	} catch ({ response }) {
		let error = response.data.message || "Can't get settings"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const updateSetting = (setting) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.updateSetting(setting)
		if (response.status !== 200) throw response
		dispatch(actions.settingUpdated({ setting }))
	} catch ({ response }) {
		let error = response.data.message || "Can't update settings"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}
