import React, { Suspense, lazy, useEffect } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { Redirect, Switch, Route } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { fetchSettings } from './modules/Setting/_redux/settings/settingsActions'

const DashboardPage = lazy(() => import('./pages/Dashboard/DashboardPage'))
const LocationPage = lazy(() => import('./modules/Location/pages/LocationPage'))
const ServicePage = lazy(() => import('./modules/Service/pages/ServicePage'))
const CouponPage = lazy(() => import('./modules/Coupon/pages/CouponPage'))
const AvailabilityPage = lazy(() =>
	import('./modules/Availability/pages/AvailabilityPage')
)
const CategoryPage = lazy(() => import('./modules/Category/pages/CategoryPage'))
const ServiceLocationPage = lazy(() =>
	import('./modules/ServiceLocation/pages/ServiceLocationPage')
)
const UserPage = lazy(() => import('./modules/User/pages/UserPage'))
const BookingPage = lazy(() => import('./modules/Booking/pages/BookingPage'))
const CartPage = lazy(() => import('./modules/Cart/pages/CartPage'))
const Profilepage = lazy(() => import('./modules/Profile/ProfilePage'))
const EmailTemplatePage = lazy(() =>
	import('./modules/EmailTemplate/pages/EmailTemplatePage')
)
const Mentorspage = lazy(() => import('./pages/MentorsPage'))
const SettingPage = lazy(() => import('./modules/Setting/SettingEditPage'))
const InvoicePage = lazy(() =>
	import('./modules/Invoice/pages/invoices/InvoicesPage')
)
const TaxratePage = lazy(() => import('./modules/Taxrate/pages/TaxratePage'))

export default function BasePage() {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchSettings())
	}, [dispatch])
	const { role_level } = useSelector(
		({ auth }) => ({
			role_level: auth.user.role_details.role_level,
		}),
		shallowEqual
	)

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Redirect exact from='/' to='/dashboard' />
				<ContentRoute path='/dashboard' component={DashboardPage} />
				<ContentRoute path='/bookings' component={BookingPage} />
				<Route path='/carts' component={CartPage} />
				<ContentRoute path='/mentors' component={Mentorspage} />
				{role_level < 8 && (
					<>
						<Route path='/locations' component={LocationPage} />
						<Route path='/services' component={ServicePage} />
						<Route path='/categories' component={CategoryPage} />
						<Route path='/servicelocations' component={ServiceLocationPage} />
						<Route path='/users' component={UserPage} />
						<Route path='/availabilities' component={AvailabilityPage} />
						<Route path='/profile' component={Profilepage} />
						<Route path='/invoices' component={InvoicePage} />
						{role_level <= 4 && (
							<>
								<Route path='/emailTemplates' component={EmailTemplatePage} />
								<Route path='/coupons' component={CouponPage} />
							</>
						)}
						{role_level <= 1 && (
							<>
								<ContentRoute path='/settings' component={SettingPage} />
								<Route path='/taxrates' component={TaxratePage} />
							</>
						)}
					</>
				)}
				<Redirect to='/error/error-v1' />
			</Switch>
		</Suspense>
	)
}
