import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import { locationsSlice } from '../app/modules/Location/_redux/locations/locationsSlice'
import { servicesSlice } from '../app/modules/Service/_redux/services/servicesSlice'
import { couponsSlice } from '../app/modules/Coupon/_redux/coupons/couponsSlice'
import { emailTemplatesSlice } from '../app/modules/EmailTemplate/_redux/emailTemplates/emailTemplatesSlice'
import { availabilitiesSlice } from '../app/modules/Availability/_redux/availabilities/availabilitiesSlice'
import { categorysSlice } from '../app/modules/Category/_redux/categorys/categorysSlice'
import { staffsSlice } from '../app/modules/User/_redux/staffs/staffsSlice'
import { serviceLocationsSlice } from '../app/modules/ServiceLocation/_redux/serviceLocations/serviceLocationsSlice'
import { customersSlice } from '../app/modules/User/_redux/customers/customersSlice'
import { bookingsSlice } from '../app/modules/Booking/_redux/bookings/bookingsSlice'
import { cartsSlice } from '../app/modules/Cart/_redux/carts/cartsSlice'
import { generalSlice } from '../app/modules/General/_redux/generalSlice'
import { settingsSlice } from '../app/modules/Setting/_redux/settings/settingsSlice'
import { dashboardSlice } from '../app/pages/Dashboard/_redux/dashboardSlice'
import { reviewsSlice } from '../app/modules/Booking/_redux/reviews/reviewsSlice'
import { profileSlice } from '../app/modules/Profile/_redux/profile/profileSlice'
import { invoicesSlice } from '../app/modules/Invoice/_redux/invoices/invoicesSlice'
import { taxratesSlice } from '../app/modules/Taxrate/_redux/taxrates/taxratesSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	locations: locationsSlice.reducer,
	services: servicesSlice.reducer,
	categorys: categorysSlice.reducer,
	staffs: staffsSlice.reducer,
	serviceLocations: serviceLocationsSlice.reducer,
	bookings: bookingsSlice.reducer,
	carts: cartsSlice.reducer,
	customers: customersSlice.reducer,
	general: generalSlice.reducer,
	dashboard: dashboardSlice.reducer,
	coupons: couponsSlice.reducer,
	emailTemplates: emailTemplatesSlice.reducer,
	availabilities: availabilitiesSlice.reducer,
	settings: settingsSlice.reducer,
	reviews: reviewsSlice.reducer,
	profile: profileSlice.reducer,
	invoices: invoicesSlice.reducer,
	taxrates: taxratesSlice.reducer,
})

export function* rootSaga() {
	yield all([auth.saga()])
}
