import { createSlice } from '@reduxjs/toolkit'

const initialProfilesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	myProfile: {},
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const profileSlice = createSlice({
	name: 'profile',
	initialState: initialProfilesState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// findProfiles
		profilesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// getProfileById
		profileFetched: (state, action) => {
			state.actionsLoading = false
			state.myProfile = action.payload.entities
			state.error = null
		},
		// updateProfile
		profileUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.myProfile = { ...state.myProfile, ...action.payload.profile }
		},
	},
})
