import axios from "axios";

export const SETTINGS_URL = process.env.REACT_APP_API_URL+"/settings";

// CREATE =>  POST: add a new setting to the server
export function findOneSetting(setting) {
  return axios.post(SETTINGS_URL, setting );
}

// READ
export function getAllSettings() {
  return axios.get(SETTINGS_URL);
}

export function getSettingById(settingId) {
  return axios.get(`${SETTINGS_URL}/${settingId}`);
}

export function getOneSetting(settingId) {
  return axios.get(`${SETTINGS_URL}/${settingId}/getOne`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSettings() {
  return axios.get(`${SETTINGS_URL}`);
}

// UPDATE => PUT: update the setting on the server
export function updateSetting(settings) {
  return axios.put(`${SETTINGS_URL}`, settings );
}

// UPDATE Status
export function updateStatusForSettings(ids, status) {
  return axios.post(`${SETTINGS_URL}/updateStatusForSettings`, {
    ids,
    status
  });
}

// DELETE => delete the setting from the server
export function deleteSetting(settingId) {
  return axios.delete(`${SETTINGS_URL}/${settingId}`);
}

// DELETE Settings by ids
export function deleteSettings(ids) {
  return axios.post(`${SETTINGS_URL}/deleteSettings`, { ids });
}

// READ ALL ADMIN USERS
export function getStaffs() {
  return axios.get("http://localhost:5000/getStaffs");
}

// READ ALL ADMIN FILTERS
export function generateFilter() {
  return axios.get(`${SETTINGS_URL}/generateFilter`);
}