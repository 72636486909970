import { createSlice } from '@reduxjs/toolkit'

const initialEmailTemplatesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	emailTemplateForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const emailTemplatesSlice = createSlice({
	name: 'emailTemplates',
	initialState: initialEmailTemplatesState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getEmailTemplateById
		emailTemplateFetched: (state, action) => {
			state.actionsLoading = false
			state.emailTemplateForEdit = action.payload.emailTemplateForEdit
			state.error = null
		},
		// findEmailTemplates
		emailTemplatesFetched: (state, action) => {
			// const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = action.payload
			// state.totalCount = totalCount
		},
		// createEmailTemplate
		emailTemplateCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateEmailTemplate
		emailTemplateUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.for === action.payload.emailTemplate.for) {
					return { ...entity, ...action.payload.emailTemplate }
				}
				return entity
			})
		},
		// deleteEmailTemplate
		emailTemplateDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deleteEmailTemplates
		emailTemplatesDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// emailTemplatesUpdateState
		emailTemplatesStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
