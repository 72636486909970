import {createSlice} from "@reduxjs/toolkit";

const initialDashboardState = {
  listLoading: false,
  actionsLoading: false,
  bookings: [],
  customers: [],
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getBookings
    bookingsFetched: (state, action) => {
      state.actionsLoading = false;
      state.bookings = action.payload.bookings;
      state.error = null;
    },
    // getCustomers
    customersFetched: (state, action) => {
      state.actionsLoading = false;
      state.customers = action.payload.customers;
      state.error = null;
    }
  }
});
