import axios from 'axios'

export const APIURL = process.env.REACT_APP_API_URL

export function login_with_user_module(data) {
	return axios.post(APIURL + '/auth/login_with_user_module', data)
}
export function login_with_username_and_password(data) {
	return axios.post(APIURL + '/auth/login_with_username_and_password', data)
}
export function login_to_business_with_token(data) {
	return axios.post(APIURL + '/auth/login_to_business_with_token', data)
}

export function login_using_keys(data) {
	return axios.post(APIURL + '/auth/login_using_keys', data)
}

export function login_using_refresh_token(data) {
	return axios.post(APIURL + '/auth/login_using_refresh_token', data)
}

export function confirm_token() {
	return axios.get(APIURL + '/')
}
