/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Tab, Nav } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../_helpers'
import { navigateToUrl } from 'single-spa'

export function QuickPanel() {
	const { modules } = useSelector(
		(state) => ({
			modules: state.auth.microsolution?.modules,
		}),
		shallowEqual,
	)

	const setNewModuleUrl = (module) => {
		const splashScreen = document.getElementById('splash-screen')
		if (splashScreen) splashScreen.classList.remove('hidden')
		navigateToUrl(`/${module.identifier}`)
	}
	const [selectedTab, setSelectedTab] = useState('AuditLogs')

	const setTab = (_tabName) => {
		setSelectedTab(_tabName)
	}

	return (
		<div id='kt_quick_panel' className='offcanvas offcanvas-left pt-5 pb-10'>
			<Tab.Container defaultActiveKey={selectedTab}>
				{/*begin::Header*/}
				<div className='offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5'>
					<Nav
						onSelect={setTab}
						as='ul'
						role='tablist'
						className='nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10'
					>
						<Nav.Item as='li'>
							<Nav.Link
								eventKey='AuditLogs'
								className={`nav-link ${
									selectedTab === 'AuditLogs' ? 'active' : ''
								}`}
							>
								Modules
							</Nav.Link>
						</Nav.Item>
					</Nav>

					<div
						className='offcanvas-close mt-n1 pr-5'
						style={{ position: 'absolute', top: '15px', right: '10px' }}
					>
						<a
							href='#'
							className='btn btn-xs btn-icon btn-light btn-hover-primary'
							id='kt_quick_panel_close'
						>
							<i className='ki ki-close icon-xs text-muted'></i>
						</a>
					</div>
				</div>
				{/*end::Header*/}

				{/*begin::Content*/}
				<div className='offcanvas-content px-10'>
					<div className='tab-content'>
						<div
							id='kt_quick_panel_logs'
							role='tabpanel'
							className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${
								selectedTab === 'AuditLogs' ? 'active show' : ''
							}`}
						>
							<div className='mb-15'>
								<h5 className='font-weight-bold mb-5'>Modules in Business</h5>
								{modules.map((module, index) => (
									<div key={index}>
										<button
											onClick={() => setNewModuleUrl(module)}
											style={{
												border: 'none',
												background: 'none',
												outline: 'inherit',
												textAlign: 'left',
											}}
										>
											<div className='d-flex align-items-center flex-wrap mb-5'>
												<div className='symbol symbol-50 symbol-light mr-5'>
													<span className='symbol-label'>
														<img
															src={toAbsoluteUrl(
																'/media/svg/icons/Layout/Layout-4-blocks.svg',
															)}
															alt=''
															className='h-50 align-self-center'
														/>
													</span>
												</div>
												<div className='d-flex flex-column flex-grow-1 mr-2'>
													<span className='font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1'>
														{module.name}
													</span>
												</div>
											</div>
										</button>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				{/*end::Content*/}
			</Tab.Container>
		</div>
	)
}
