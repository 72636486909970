/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { checkIsActive } from '../../../../_helpers'
import DashboardIcon from '@material-ui/icons/Dashboard'
import EventIcon from '@material-ui/icons/Event'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService'
import TuneIcon from '@material-ui/icons/Tune'
import PeopleIcon from '@material-ui/icons/People'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import GroupIcon from '@material-ui/icons/Group'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'

export function AsideMenuList({ layoutProps }) {
	const { role_level } = useSelector(
		(state) => state.auth.user.role_details,
		shallowEqual
	)
	const location = useLocation()
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: ''
	}

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/dashboard'>
						<span className='svg-icon menu-icon'>
							<DashboardIcon />
						</span>
						<span className='menu-text'>Dashboard</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{/* Admin Settings */}
				{/* begin::section */}
				<li className='menu-section '>
					<h4 className='menu-text'>Application</h4>
					<i className='menu-icon flaticon-more-v2'></i>
				</li>
				{/* end:: section */}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive('/bookings', false)}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/bookings'>
						<span className='svg-icon menu-icon'>
							<EventIcon />
						</span>
						<span className='menu-text'>Booking</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive('/carts', false)}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/carts'>
						<span className='svg-icon menu-icon'>
							<ShoppingCartIcon />
						</span>
						<span className='menu-text'>Cart</span>
					</NavLink>
				</li>
				{/*end::1 */}
				{/*begin::1 Level */}
				<li
					className={`menu-item ${getMenuItemActive('/mentors', false)}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/mentors'>
						<span className='svg-icon menu-icon'>
							<GroupIcon />
						</span>
						<span className='menu-text'>Mentors</span>
					</NavLink>
				</li>
				{/*end::1 */}
				{role_level < 8 && (
					<>
						{/* Admin Settings */}
						{/* begin::section */}
						<li className='menu-section '>
							<h4 className='menu-text'>Staff Settings</h4>
							<i className='menu-icon flaticon-more-v2'></i>
						</li>
						{/* end:: section */}

						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive('/locations', false)}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/locations'>
								<span className='svg-icon menu-icon'>
									<MyLocationIcon />
								</span>
								<span className='menu-text'>Location</span>
							</NavLink>
						</li>
						{/*end::1 Level*/}

						{/*begin::1 Level*/}
						<li
							className={`menu-item menu-item-submenu ${getMenuItemActive(
								'/services',
								true
							)}`}
							aria-haspopup='true'
							data-menu-toggle='hover'
						>
							<NavLink className='menu-link menu-toggle' to='/services'>
								<span className='svg-icon menu-icon'>
									<LocalLaundryServiceIcon />
								</span>
								<span className='menu-text'>Service</span>
								<i className='menu-arrow' />
							</NavLink>
							<div className='menu-submenu '>
								<i className='menu-arrow' />
								<ul className='menu-subnav'>
									<li
										className='menu-item  menu-item-parent'
										aria-haspopup='true'
									>
										<span className='menu-link'>
											<span className='menu-text'>Service</span>
										</span>
									</li>

									{/*begin::2 Level*/}
									<li
										className={`menu-item ${getMenuItemActive('/categories')}`}
										aria-haspopup='true'
									>
										<NavLink className='menu-link' to='/categories'>
											<i className='menu-bullet menu-bullet-dot'>
												<span />
											</i>
											<span className='menu-text'>Category</span>
										</NavLink>
									</li>
									{/*begin::2 Level*/}
									<li
										className={`menu-item ${getMenuItemActive('/services')}`}
										aria-haspopup='true'
									>
										<NavLink className='menu-link' to='/services'>
											<i className='menu-bullet menu-bullet-dot'>
												<span />
											</i>
											<span className='menu-text'>Service</span>
										</NavLink>
									</li>
									{/*end::2 Level*/}

									{/*end::2 Level*/}
								</ul>
							</div>
						</li>
						{/*end::1 Level*/}

						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive(
								'/availabilities',
								false
							)}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/availabilities'>
								<span className='svg-icon menu-icon'>
									<EventAvailableIcon />
								</span>
								<span className='menu-text'>Availability</span>
							</NavLink>
						</li>
						{/*end::1 Level*/}
						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive('/invoices', false)}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/invoices'>
								<span className='svg-icon menu-icon'>
									<EventAvailableIcon />
								</span>
								<span className='menu-text'>Invoice</span>
							</NavLink>
						</li>
						{/*end::1 Level*/}
						{/*begin::1 Level*/}
						<li
							className={`menu-item menu-item-submenu ${getMenuItemActive(
								'/users',
								true
							)}`}
							aria-haspopup='true'
							data-menu-toggle='hover'
						>
							<NavLink className='menu-link menu-toggle' to='/users'>
								<span className='svg-icon menu-icon'>
									<PeopleIcon />
								</span>
								<span className='menu-text'>Users</span>
								<i className='menu-arrow' />
							</NavLink>
							<div className='menu-submenu '>
								<i className='menu-arrow' />
								<ul className='menu-subnav'>
									<li
										className='menu-item  menu-item-parent'
										aria-haspopup='true'
									>
										<span className='menu-link'>
											<span className='menu-text'>Users</span>
										</span>
									</li>

									{/*begin::2 Level*/}
									<li
										className={`menu-item ${getMenuItemActive(
											'/users/staffs'
										)}`}
										aria-haspopup='true'
									>
										<NavLink className='menu-link' to='/users/staffs'>
											<i className='menu-bullet menu-bullet-dot'>
												<span />
											</i>
											<span className='menu-text'>Staffs</span>
										</NavLink>
									</li>
									{/*end::2 Level*/}

									{/*begin::2 Level*/}
									<li
										className={`menu-item ${getMenuItemActive(
											'/users/customers'
										)}`}
										aria-haspopup='true'
									>
										<NavLink className='menu-link' to='/users/customers'>
											<i className='menu-bullet menu-bullet-dot'>
												<span />
											</i>
											<span className='menu-text'>Customers</span>
										</NavLink>
									</li>
									{/*end::2 Level*/}
								</ul>
							</div>
						</li>
						{/*end::1 Level*/}
						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive('/profile', false)}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/profile'>
								<span className='svg-icon menu-icon'>
									<AccountCircleIcon />
								</span>
								<span className='menu-text'>Profile</span>
							</NavLink>
						</li>
						{/*begin::1 Level*/}
						{role_level <= 4 && (
							<>
								<li
									className={`menu-item ${getMenuItemActive(
										'/emailTemplates',
										false
									)}`}
									aria-haspopup='true'
								>
									<NavLink className='menu-link' to='/emailTemplates'>
										<span className='svg-icon menu-icon'>
											<AlternateEmailIcon />
										</span>
										<span className='menu-text'>Email Templates</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/coupons',
										false
									)}`}
									aria-haspopup='true'
								>
									<NavLink className='menu-link' to='/coupons'>
										<span className='svg-icon menu-icon'>
											<ConfirmationNumberIcon />
										</span>
										<span className='menu-text'>Coupons</span>
									</NavLink>
								</li>
							</>
						)}
						{/*end::1 Level*/}
						{/* Admin Settings */}
						{/*begin::1 Level*/}
						{role_level <= 1 && (
							<>
								<li className='menu-section '>
									<h4 className='menu-text'>Admin Settings</h4>
									<i className='menu-icon flaticon-more-v2'></i>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/taxrates',
										false
									)}`}
									aria-haspopup='true'
								>
									<NavLink className='menu-link' to='/taxrates'>
										<span className='svg-icon menu-icon'>
											<TuneIcon />
										</span>
										<span className='menu-text'>Tax Rates</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/settings',
										false
									)}`}
									aria-haspopup='true'
								>
									<NavLink className='menu-link' to='/settings'>
										<span className='svg-icon menu-icon'>
											<TuneIcon />
										</span>
										<span className='menu-text'>Setting</span>
									</NavLink>
								</li>
							</>
						)}
						{/*end::1 Level*/}
					</>
				)}
			</ul>
			{/* end::Menu Nav */}
		</>
	)
}
