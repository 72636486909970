import { createSlice } from '@reduxjs/toolkit'

const initialInvoicesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: null,
	invoiceForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const invoicesSlice = createSlice({
	name: 'invoices',
	initialState: initialInvoicesState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getInvoiceById
		invoiceFetched: (state, action) => {
			state.actionsLoading = false
			state.invoiceForEdit = action.payload.invoiceForEdit
			state.error = null
		},
		// findInvoices
		invoicesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// createInvoice
		invoiceCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateInvoice
		invoiceUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.invoice.id) {
					return { ...entity, ...action.payload.invoice }
				}
				return entity
			})
		},
		// deleteInvoice
		invoiceDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deleteInvoices
		invoicesDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// invoicesUpdateState
		invoicesStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
