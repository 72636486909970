import {createSlice} from "@reduxjs/toolkit";

const initialCartsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  cartToView: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const cartsSlice = createSlice({
  name: "carts",
  initialState: initialCartsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCartById
    cartFetched: (state, action) => {
      state.actionsLoading = false;
      state.cartToView = action.payload.cartToView;
      state.error = null;
    },
    // findCarts
    cartsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createCart
    cartCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
    },
    // updateCart
    cartUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.cart.id) {
          return action.payload.cart;
        }
        return entity;
      });
    },
    // deleteCart
    cartDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteBooking
    bookingDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.cartToView.bookings = state.cartToView.bookings.filter(el => el.id !== action.payload.id);
    },
    // deleteCarts
    cartsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // cartsUpdateState
    cartsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    // makePayment
    makePayment: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.cartToView ? state.cartToView = action.payload :
      state.entities = state.entities.map(entity => {
        if (entity.id === Number(action.payload.id)) {
          entity = action.payload
        }
        return entity;
      });
    },
  }
});
