import { createSlice } from '@reduxjs/toolkit'

const initialStaffsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  staffForEdit: undefined,
  lastError: null,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const staffsSlice = createSlice({
  name: 'staffs',
  initialState: initialStaffsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // getStaffById
    staffFetched: (state, action) => {
      state.actionsLoading = false
      state.staffForEdit = action.payload.staffForEdit
      state.error = null
    },
    // findStaffs
    staffsFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // createStaff
    staffCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.entities.push(action.payload.data)
    },
    // updateStaff
    staffUpdated: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.staff.id) {
          return action.payload.staff
        }
        return entity
      })
    },
    // deleteStaff
    staffDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      )
    },
    // deleteStaffs
    staffsDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      )
    },
    // staffsUpdateState
    staffsStatusUpdated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      const { ids, status } = action.payload
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status
        }
        return entity
      })
    },
  },
})
